//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Warning from "@/components/Alert/Warning.vue";
export default {
  components: {
    Warning
  },
  name: "WarningRepricer",
  computed: {
    notValidTokens() {
      return this.$store.state.repricer.notValidTokens;
    },
    tokens() {
      return this.$store.state.repricer.tokens;
    },
    notValidTokensString() {
      const tokens = this.notValidTokens;
      return tokens.map(token => token.name).join(", ");
    }
  }
};