//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoTokens from "@/components/NoTokensRepricer.vue";
export default {
  props: {
    small: {
      type: Boolean
    },
    text: {
      type: String
    },
    img: {
      type: String
    }
  },
  computed: {
    hasAccess() {
      // return true;
      return !this.$access.check("repricer");
    },
    tokens() {
      return this.$store.state.repricer.tokens;
    },
    shouldShowModal() {
      return !(this.$store.state.user.user && !this.$store.state.repricer.loaded) && this.$store.state.repricer.hasValidTokens && !this.hasAccess;
    }
  },
  watch: {
    shouldShowModal: {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        this.$modal.open(() => import("@/components/modals/access/ModalTokensNoSubscription"));
      }
    }
  },
  components: {
    NoTokens
  }
};