//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable.vue";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    value: {
      type: Array
    },
    total: {
      type: Number,
      required: false,
      default: 10
    },
    success: {
      type: Boolean,
      required: false,
      default: true
    },
    message: {
      type: String,
      required: false,
      default: "Ok"
    }
    // withoutCostLoader: {
    //     type: Function,
    // },
  },

  data() {
    const defaultColumns = [{
      title: "SKU",
      show: true,
      name: "sku",
      type: "slot",
      width: 110,
      filter: "text",
      noOverflow: true,
      sort: false
    }, {
      title: `Минимальная цена (руб.)`,
      show: true,
      name: "min_price",
      type: "slot",
      width: 160,
      filter: "numberRange",
      sort: false
    }, {
      title: `Максимальная цена (руб.)`,
      show: true,
      name: "max_price",
      type: "slot",
      width: 160,
      filter: "numberRange",
      sort: false
    }];
    return {
      columns: defaultColumns,
      defaultColumns,
      currentMinEdit: "",
      editMinId: null,
      currentMaxEdit: "",
      editMaxId: null,
      currentExpectedEdit: "",
      editExpectedId: null
    };
  },
  methods: {
    changeMinCost(item) {
      this.editMinId = item.id;
      if (item.min_price !== null) {
        this.currentMinEdit = item.min_price !== -1 ? item.min_price : "";
      }
    },
    cancelMinChanging() {
      this.currentMinEdit = "";
      this.editMinId = null;
    },
    changeMaxCost(item) {
      this.editMaxId = item.id;
      if (item.max_price !== null) {
        this.currentMaxEdit = item.max_price !== -1 ? item.max_price : "";
      }
    },
    cancelMaxChanging() {
      this.currentMaxEdit = "";
      this.editMaxId = null;
    },
    changeExpectedCost(item) {
      this.editExpectedId = item.id;
      if (item.target_price !== null) {
        this.currentExpectedEdit = item.target_price !== -1 ? item.target_price : "";
      }
    },
    cancelExpectedChanging() {
      this.currentExpectedEdit = "";
      this.editExpectedId = null;
    },
    async saveMinCost(item) {
      const newValue = this.value;
      const itemToUpdate = newValue.find(product => product.id === item.id);
      itemToUpdate.min_price = this.currentMinEdit;
      this.$emit("input", newValue);
      if (!itemToUpdate.new) {
        this.$emit("updateItem", {
          ...itemToUpdate
        });
      }
      this.currentMinEdit = "";
      this.editMinId = null;
      this.refresh();
      // this.withoutCostLoader();
    },

    async saveMaxCost(item) {
      const newValue = this.value;
      const itemToUpdate = newValue.find(product => product.id === item.id);
      itemToUpdate.max_price = this.currentMaxEdit;
      this.$emit("input", newValue);
      if (!itemToUpdate.new) {
        this.$emit("updateItem", {
          ...itemToUpdate
        });
      }
      this.currentMaxEdit = "";
      this.editMaxId = null;
      this.refresh();
      // this.withoutCostLoader();
    },

    async saveExpectedCost(item) {
      const newValue = this.value;
      const itemToUpdate = newValue.find(product => product.id === item.id);
      itemToUpdate.target_price = this.currentExpectedEdit;
      this.$emit("input", newValue);
      if (!itemToUpdate.new) {
        this.$emit("updateItem", {
          ...itemToUpdate
        });
      }
      this.currentExpectedEdit = "";
      this.editExpectedId = null;
      this.refresh();
      // this.withoutCostLoader();
    },

    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      this.$emit("updateQuery", query);
      let result = [];
      result = this.value;
      result = {
        items: [...result],
        total: this.total,
        success: this.success,
        message: this.message
      };
      return result;
    },
    updateColumns() {
      if (this.type === "compensation") {
        this.columns = [...this.defaultColumns.filter(item => item.name === "sku"), {
          title: `Ожидаемая цена (руб.)`,
          show: true,
          name: "target_price",
          type: "slot",
          width: 160,
          filter: "numberRange",
          sort: false
        }];
      } else {
        this.columns = [...this.defaultColumns];
      }
    }
  },
  created() {
    this.updateColumns();
  },
  computed: {
    type() {
      return this.reportSettings.type;
    }
  },
  watch: {
    value() {
      this.refresh();
    },
    type() {
      this.updateColumns();
    }
  }
};