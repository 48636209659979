/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',_vm._b({ref:"table",attrs:{"table-id":"Products","id":_vm.id,"columns":_vm.columns,"loader":_vm.tableLoader,"loaderConfig":_vm.reportSettings,"item-id":"id","selectAction":_vm.type === 'compensation'
            ? 'repricer-products-edit-compensation'
            : 'repricer-products-edit',"selectable":_vm.selectable,"addable":true,"paginatable":_vm.paginatable,"configurable":_vm.configurable},on:{"selectAction":function($event){return _vm.$emit('selectAction', $event)},"selectAction2":function($event){return _vm.$emit('selectAction2', $event)},"selectAction3":function($event){return _vm.$emit('selectAction3', $event)},"selectAction4":function($event){return _vm.$emit('selectAction4', $event)},"addNew":function($event){return _vm.$emit('addNew')}},scopedSlots:_vm._u([{key:"sku",fn:function(ref){
            var item = ref.item;
return [(item.new)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.sku),expression:"item.sku"}],staticClass:"input",attrs:{"type":"text","placeholder":"1000"},domProps:{"value":(item.sku)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "sku", $event.target.value)}}})]):_c('div',[_c('span',[_vm._v(_vm._s(item.sku))])])]}},{key:"min_price",fn:function(ref){
            var item = ref.item;
return [(item.new)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.min_price),expression:"item.min_price"}],staticClass:"input",attrs:{"type":"text","placeholder":"1000"},domProps:{"value":(item.min_price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "min_price", $event.target.value)}}})]):_c('div',[(_vm.editMinId !== item.id)?_c('div',{staticClass:"cost"},[(_vm.editMinId !== item.id)?_c('span',{staticClass:"cost__text"},[(
                            item.min_price === -1 || item.min_price === null
                        )?_c('span',{staticClass:"gray"},[_vm._v(" — ")]):_c('span',[_vm._v(_vm._s(item.min_price))])]):_vm._e(),_c('button',{staticClass:"edit-btn",on:{"click":function () { return _vm.changeMinCost(item); }}},[_c('img',{staticClass:"edit-icon",attrs:{"src":require("../../assets/images/icons/edit.svg"),"alt":"Edit"}})])]):_c('div',{staticClass:"cost"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentMinEdit),expression:"currentMinEdit"}],staticClass:"bold",attrs:{"type":"text","autofocus":""},domProps:{"value":(_vm.currentMinEdit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentMinEdit=$event.target.value}}}),_c('button',{staticClass:"ok-btn",on:{"click":function () { return _vm.saveMinCost(item); }}},[_c('img',{staticClass:"ok-icon",attrs:{"src":require("../../assets/images/icons/ok.svg"),"alt":"Ok"}})]),_c('button',{staticClass:"cancel-btn",on:{"click":_vm.cancelMinChanging}},[_c('img',{staticClass:"cancel-icon",attrs:{"src":require("../../assets/images/icons/cancel.svg"),"alt":"Cancel"}})])])])]}},{key:"max_price",fn:function(ref){
                        var item = ref.item;
return [(item.new)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.max_price),expression:"item.max_price"}],staticClass:"input",attrs:{"type":"text","placeholder":"1000"},domProps:{"value":(item.max_price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "max_price", $event.target.value)}}})]):_c('div',[(_vm.editMaxId !== item.id)?_c('div',{staticClass:"cost"},[(_vm.editMaxId !== item.id)?_c('span',{staticClass:"cost__text"},[(
                            item.max_price === -1 || item.max_price === null
                        )?_c('span',{staticClass:"gray"},[_vm._v(" — ")]):_c('span',[_vm._v(_vm._s(item.max_price))])]):_vm._e(),_c('button',{staticClass:"edit-btn",on:{"click":function () { return _vm.changeMaxCost(item); }}},[_c('img',{staticClass:"edit-icon",attrs:{"src":require("../../assets/images/icons/edit.svg"),"alt":"Edit"}})])]):_c('div',{staticClass:"cost"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentMaxEdit),expression:"currentMaxEdit"}],staticClass:"bold",attrs:{"type":"text","autofocus":""},domProps:{"value":(_vm.currentMaxEdit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentMaxEdit=$event.target.value}}}),_c('button',{staticClass:"ok-btn",on:{"click":function () { return _vm.saveMaxCost(item); }}},[_c('img',{staticClass:"ok-icon",attrs:{"src":require("../../assets/images/icons/ok.svg"),"alt":"Ok"}})]),_c('button',{staticClass:"cancel-btn",on:{"click":_vm.cancelMaxChanging}},[_c('img',{staticClass:"cancel-icon",attrs:{"src":require("../../assets/images/icons/cancel.svg"),"alt":"Cancel"}})])])])]}},{key:"target_price",fn:function(ref){
                        var item = ref.item;
return [(item.new)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.target_price),expression:"item.target_price"}],staticClass:"input",attrs:{"type":"text","placeholder":"1000"},domProps:{"value":(item.target_price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "target_price", $event.target.value)}}})]):_c('div',[(_vm.editExpectedId !== item.id)?_c('div',{staticClass:"cost"},[(_vm.editExpectedId !== item.id)?_c('span',{staticClass:"cost__text"},[(
                            item.target_price === -1 ||
                            item.target_price === null
                        )?_c('span',{staticClass:"gray"},[_vm._v(" — ")]):_c('span',[_vm._v(_vm._s(item.target_price))])]):_vm._e(),_c('button',{staticClass:"edit-btn",on:{"click":function () { return _vm.changeExpectedCost(item); }}},[_c('img',{staticClass:"edit-icon",attrs:{"src":require("../../assets/images/icons/edit.svg"),"alt":"Edit"}})])]):_c('div',{staticClass:"cost"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentExpectedEdit),expression:"currentExpectedEdit"}],staticClass:"bold",attrs:{"type":"text","autofocus":""},domProps:{"value":(_vm.currentExpectedEdit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentExpectedEdit=$event.target.value}}}),_c('button',{staticClass:"ok-btn",on:{"click":function () { return _vm.saveExpectedCost(item); }}},[_c('img',{staticClass:"ok-icon",attrs:{"src":require("../../assets/images/icons/ok.svg"),"alt":"Ok"}})]),_c('button',{staticClass:"cancel-btn",on:{"click":_vm.cancelExpectedChanging}},[_c('img',{staticClass:"cancel-icon",attrs:{"src":require("../../assets/images/icons/cancel.svg"),"alt":"Cancel"}})])])])]}}])},'data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }